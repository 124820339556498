import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {DashboardLayoutComponent} from "../../view/dashboard-layout/dashboard-layout.component";
import {Router} from "@angular/router";

@Component({
    selector: 'asappz-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

    constructor(
        public appMain: DashboardLayoutComponent,
        public auth: AuthService,
        public router: Router,
    ) {
    }

    public signOutHandler(): Promise<boolean | void> {
        return this.auth.signOutHandler().then(() => this.router.navigate(['../'],)
        ).catch(error => console.log("Error on signOutHandler: ", error))
    }
}
