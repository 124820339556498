import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

// Demo services
import {ConfigService} from './service/app.config.service';

// Application services
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {provideAuth, getAuth, Auth} from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideStorage,getStorage } from '@angular/fire/storage';
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, SharedModule} from 'primeng/api';
import {ToastModule} from "primeng/toast";
import {TableModule} from "primeng/table";
import {RatingModule} from "primeng/rating";
import {InputTextModule} from "primeng/inputtext";
import {ToolbarModule} from "primeng/toolbar";
import {MenuModule} from "./components/menu/menu.module";
import {TopbarModule} from "./components/topbar/topbar.module";
import {AuthService} from './service/auth.service';

@NgModule({
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        provideFirestore(() => getFirestore()),
        provideFunctions(() => getFunctions()),
        provideMessaging(() => getMessaging()),
        providePerformance(() => getPerformance()),
        provideStorage(() => getStorage()),
        AngularFireAuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        ButtonModule,
        RippleModule,
        SharedModule,
        ToastModule,
        TableModule,
        RatingModule,
        InputTextModule,
        ToolbarModule,
        MenuModule,
        TopbarModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        ConfigService,
        MessageService,
        ConfirmationService,
        AuthService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
