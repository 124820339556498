import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IAppConfig {
    theme: string;
    dark: boolean;
    inputStyle: 'outlined';
    ripple: boolean;
}

@Injectable()
export class ConfigService {

    config: IAppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    private configUpdate = new Subject<IAppConfig>();

    updateConfig(config: IAppConfig) {
        this.config = config;
        this.configUpdate.next(config);
    }
}
