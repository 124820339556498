import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopbarComponent} from './topbar.component';
import {ButtonModule} from "primeng/button";
import {RouterModule} from "@angular/router";


@NgModule({
    declarations: [
        TopbarComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        RouterModule
    ],
    exports: [
        TopbarComponent
    ],
})
export class TopbarModule {
}
