import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
    selector: 'asappz-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    animations: [
        trigger('mask-anim', [
            state('void', style({opacity: 0})),
            state('visible', style({opacity: 0.8})),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)')),
        ]),
    ],
})
export class DashboardLayoutComponent {
    public rightPanelActive: boolean;

    public menuClick: boolean;

    public staticMenuActive: boolean;

    public menuMobileActive: boolean;

    public megaMenuClick: boolean;

    public megaMenuMobileClick: boolean;

    public megaMenuActive: boolean;

    public megaMenuMobileActive: boolean;

    public topBarMobileMenuClick: boolean;

    public topBarMobileMenuActive: boolean;

    public topBarMenuActive: boolean;

    public menuHoverActive: boolean;

    public configActive: boolean;

    public configClick: boolean;

    public sidebarActive: boolean;

    public activeTopBarItem: any;

    public topBarItemClick: any;

    public rightPanelClick: boolean;

    constructor() {
        this.staticMenuActive = true;
    }

    public onMenuButtonClick(event): void {
        this.menuClick = true;
        this.topBarMenuActive = false;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    public onMegaMenuClick(event): void {
        this.megaMenuClick = true;
        event.preventDefault();
    }

    public onRightPanelButtonClick(event): void {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        event.preventDefault();
    }

    public onTopBarMobileMenuButtonClick(event): void {
        this.topBarMobileMenuClick = true;
        this.topBarMobileMenuActive = !this.topBarMobileMenuActive;

        event.preventDefault();
    }

    public onMegaMenuMobileButtonClick(event): void {
        this.megaMenuMobileClick = true;
        this.megaMenuMobileActive = !this.megaMenuMobileActive;

        event.preventDefault();
    }


    public onRightPanelClose(event): void {
        this.rightPanelActive = false;
        this.rightPanelClick = false;

        event.preventDefault();
    }

    public onRightPanelClick(event): void {
        this.rightPanelClick = true;

        event.preventDefault();
    }

    public onLayoutClick(): void {
        if (!this.topBarItemClick) {
            this.activeTopBarItem = null;
            this.topBarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.megaMenuClick) {
            this.megaMenuActive = false;
        }

        if (!this.megaMenuMobileClick) {
            this.megaMenuMobileActive = false;
        }

        if (!this.menuClick) {
            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }

            this.menuHoverActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.megaMenuClick = false;
    }

    public onSidebarClick(event: Event): void {
        this.menuClick = true;
    }

    public onToggleMenuClick(event: Event): void {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }

    public isDesktop(): boolean {
        return window.innerWidth > 991;
    }

    public isMobile(): boolean {
        return window.innerWidth <= 991;
    }
}