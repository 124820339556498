import { Component, OnInit } from '@angular/core';

export interface IMenuItem {
  label: string;
  icon?: string;
  routerLink?: any[] | string | null | undefined;
  items?: IMenuItem[];
}

@Component({
  selector: 'asappz-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  public model: IMenuItem[];

  constructor() {}

  public ngOnInit(): void {
    this.model = [
      {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/crud']},
      { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/empty'] },
    ];
  }
}
