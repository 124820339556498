import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: '', loadChildren: () => import('../app/view/login/login.module').then(m => m.LoginModule) },
            {path: 'dashboard', loadChildren: () => import('../app/view/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule) },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
