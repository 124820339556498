import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';

@Component({
    selector: 'asappz-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    public horizontalMenu: boolean;

    public darkMode = false;

    public menuColorMode = 'light';

    public menuColor = 'layout-menu-light';

    public ripple = true;

    public inputStyle = 'outlined';

    constructor(private primengConfig: PrimeNGConfig) {}

    public ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
