import {Injectable} from '@angular/core';
import {collectionData, collectionGroup, deleteDoc, doc, docData, Firestore, writeBatch} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {QueryDocumentSnapshot} from 'firebase/firestore';
import {FIRESTORE_COLLECTION_PATH, ILandlordProfile, ITenantProfile, IUserWithRoles} from '@wohnsinn/ws-ts-lib';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private firestore: Firestore) { }

    private converter = <T>() => ({
        toFirestore: (data: T) => {
            return {
                ...data,
            } as T;
        },
        fromFirestore: (snap: QueryDocumentSnapshot) => {
            return {
                ...snap.data(),
                id: snap.id,
            } as unknown as T;
        },
    });

    public deleteListOfUsers(userList: ITenantProfile[] | ILandlordProfile[]): Promise<void> {
        const batch = writeBatch(this.firestore);

        userList.forEach((user) => {
            batch.delete(doc(
                this.firestore,
                `users/${user.uid}`
            ))
        });
        return batch.commit();
    }

      public deleteUser(user: ITenantProfile | ILandlordProfile): Promise<void> {
        return deleteDoc(doc(this.firestore, `users/${user.uid}`));
      }

      public getTenantProfile$(uid): Observable<ITenantProfile> {
        const profileDocRef = doc(this.firestore, `${FIRESTORE_COLLECTION_PATH.users.tenantProfiles.root.replace('{uid}', uid)}/${uid}`)
            .withConverter(this.converter<ITenantProfile>());
        return docData(profileDocRef);
      }

      public getUserWithRoles$(uid): Observable<IUserWithRoles> {
        const profileDocRef = doc(this.firestore, `${FIRESTORE_COLLECTION_PATH.users.root}/${uid}`)
            .withConverter(this.converter<IUserWithRoles>());
        return docData(profileDocRef);
      }

      public getTenantList$(): Observable<ITenantProfile[]> {
          const profileColRef = collectionGroup(this.firestore, `tenantProfiles`).withConverter(this.converter<ITenantProfile>());
          return collectionData(profileColRef);
      }

      public getLandlord$(uid): Observable<ILandlordProfile> {
        const profileDocRef = doc(this.firestore, `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.root.replace('{uid}', uid)}/${uid}`)
            .withConverter(this.converter<ILandlordProfile>());
        return docData(profileDocRef);
      }

      public getLandlordList$(): Observable<ILandlordProfile[]> {
         const profileColRef = collectionGroup(this.firestore, `landlordProfiles`).withConverter(this.converter<ILandlordProfile>());
         return collectionData(profileColRef);
      }

}
