import {Injectable, Optional} from '@angular/core';
import {
    Auth,
    authState,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    UserCredential
} from '@angular/fire/auth';
import {Observable, of, switchMap} from "rxjs";
import {UserService} from "./user.service";
import {IUserWithRoles} from '@wohnsinn/ws-ts-lib';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user$: Observable<IUserWithRoles | null> = of(null);

    constructor(
        private userService: UserService,
        @Optional() public auth: Auth,
    ) {
        this.user$ = authState(this.auth).pipe(
            switchMap((user) => {
                if (user) {
                    return this.userService.getUserWithRoles$(user.uid)
                }
                return of(null);
            }),
        );
    }

    public signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(getAuth(), email, password).then((credentials) => {
            return credentials;
        })
    }

    public signInWithGoogle(): Promise<UserCredential> {
        const GOOGLE_AUTHORIZATION_PROVIDER = new GoogleAuthProvider();

        return signInWithPopup(getAuth(), GOOGLE_AUTHORIZATION_PROVIDER).then((credentials) => {
            return credentials;
        })
    }

    public signOutHandler(): Promise<void> {
        return getAuth().signOut();
    }
}
